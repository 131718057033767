const datepicker = require('js-datepicker');
class ComponentFormOrder {

    constructor() {
        this.orderForm = document.querySelector("[data-form-order]");

        if (this.orderForm) {
            this.initFormOrder();

            this.datePicker = this.orderForm.querySelector('[data-order-datepicker]');

            if (this.datePicker){
                this.initDatePicker();
            }
        }
    }

    initFormOrder() {

        const vinInput = this.orderForm.querySelector('[data-vin-input]');
        const vinCharCounter = this.orderForm.querySelector('[data-vin-count]');

        vinInput.addEventListener('input', function () {
            const count = vinInput.value.length;
            vinCharCounter.textContent = count + '/17';
        });

    }

    initDatePicker() {
        const selector = this.datePicker;
        const options = {
            // Event callbacks.
           /* onSelect: instance => {
                // Show which date was selected.
                console.log(instance.dateSelected)
            },
                onShow: instance => {
                console.log('Calendar showing.')
            },
                onHide: instance => {
                console.log('Calendar hidden.')
            },
                onMonthChange: instance => {
                // Show the month of the selected date.
                console.log(instance.currentMonthName)
            },

                // Customizations.
                formatter: (input, date, instance) => {
                // This will display the date as `1/1/2019`.
                input.value = date.toDateString()
            },*/
            formatter: (input, date, instance) => {
                const value = date.toLocaleDateString()
                input.value = value // => '1/1/2099'
            },
            position: 'bl', // Top right.
            startDay: 1, // Calendar week starts on a Monday.
            customDays: ['Ne', 'Po', 'Út', 'St', 'Čt', 'Pá', 'So'],
            customMonths: ['Leden', 'Únor', 'Březen', 'Duben', 'Květen', 'Červen', 'Červenec', 'Srpen', 'Září', 'Říjen', 'Listopad', 'Prosinec'],
            //customOverlayMonths: ['😀', '😂', '😎', '😍', '🤩', '😜', '😬', '😳', '🤪', '🤓 ', '😝', '😮'],
            //overlayButton: 'Go!',
            //overlayPlaceholder: 'Enter a 4-digit year',

            // Settings.
            alwaysShow: false, // Never hide the calendar.
            //dateSelected: new Date(), // Today is selected.
            maxDate: new Date(2099, 0, 1), // Jan 1st, 2099.
            minDate: new Date(), // today
            startDate: new Date(), // This month.
            showAllDates: true, // Numbers for leading & trailing days outside the current month will show.

            // Disabling things.
            noWeekends: false, // Saturday's and Sunday's will be unselectable.
            disabler: date => (date.getDay() === 0), // Disabled every Tuesday in October
            //disabledDates: [new Date(2050, 0, 1), new Date(2050, 0, 3)], // Specific disabled dates.
            //disableMobile: true, // Conditionally disabled on mobile devices.
            disableYearOverlay: true, // Clicking the year or month will *not* bring up the year overlay.

        }
        const picker = datepicker(selector, options);
    }

} export default ComponentFormOrder;
