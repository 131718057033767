import { tns } from "tiny-slider";

class ComponentTestimonialsSlider {

    constructor() {
        this.tinySliderSection = document.querySelector(".s-testimonials");

        if (this.tinySliderSection) {
            this.slider = document.querySelector(".s-testimonials__wrapper");

            this.sliderControls = document.querySelector(".s-testimonials__controls");
            this.prevBtn = this.sliderControls.querySelector("[data-slider-prev]");
            this.nextBtn = this.sliderControls.querySelector("[data-slider-next]");
            //
            // this.navContainer = document.querySelector(".testimonials-slider-indicators");
            // this.indicatorsList = this.navContainer.querySelector("[data-indicators-list]");

            this.initSlider();
        }
    }

    initSlider() {
       // const indicatorsList = this.indicatorsList;
        const spacer = 20;

        let tinySlider = tns({
            container: this.slider,
            items: 1,
            gutter: spacer,
            edgePadding: spacer,
            //lazyload: true,
            //lazyloadSelector: '.testimonials-slide-header img',
            controlsContainer: this.sliderControls,
            prevButton: this.prevBtn,
            nextButton: this.nextBtn,
            autoplay: true,
            mouseDrag: true,
            center: true,
            nav: false,
            autoHeight: true,
            // onInit: function(info) {
            //     info.controlsContainer.setAttribute('tabindex', -1);
            //     info.nextButton.setAttribute('tabindex', 0);
            //     info.prevButton.setAttribute('tabindex', 0);
            // },

            //navContainer: this.indicatorsList,
            //fixedWidth: 390,
            /*responsive: {
                576: {
                    items: 1.25,
                    //gutter: 80,
                    //edgePadding: 25
                },
                768: {
                    items: 2,
                    //gutter: 80,
                    //edgePadding: 25
                },
                992: {
                    items: 2,
                    //gutter: 60,
                    //edgePadding: 20
                },
                1199: {
                    items: 2.75,
                    //gutter: 60,
                    //edgePadding: 20
                },
                1440: {
                    items: 3,
                    gutter: spacer*6,
                    edgePadding: spacer*6
                }
            }*/
        });

    }

} export default ComponentTestimonialsSlider;
