class ComponentOpenerVideo {
    constructor() {
        this.openerEl = document.querySelector("[data-opener-video-src]");

        if (this.openerEl) {
            this.initOpenerVideo();
        }
    }

    initOpenerVideo() {
        const openerOverlay = this.openerEl.querySelector('.opener-overlay--video');
        const video = document.createElement('video');
        const videoSrc = this.openerEl.dataset.openerVideoSrc;// video.src = 'assets/video/video.mp4';

        video.classList.add('opener-video');
        video.controls = false;
        video.autoplay = true;
        video.loop = true;
        video.muted = true;
        video.preload = 'auto';
        video.src = videoSrc;
        video.play();
        openerOverlay.appendChild(video);
    }
} export default ComponentOpenerVideo;