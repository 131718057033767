import { tns } from "tiny-slider";

class ComponentPartnersSlider {

    constructor() {
        this.tinySliderSection = document.querySelector(".s-partners");

        if (this.tinySliderSection) {
            this.slider = document.querySelector(".s-partners__wrapper");

            this.sliderControls = document.querySelector(".s-partners__controls");
            this.prevBtn = this.sliderControls.querySelector("[data-slider-prev]");
            this.nextBtn = this.sliderControls.querySelector("[data-slider-next]");
            //
            // this.navContainer = document.querySelector(".testimonials-slider-indicators");
            // this.indicatorsList = this.navContainer.querySelector("[data-indicators-list]");

            this.initSlider();
        }
    }

    initSlider() {
       // const indicatorsList = this.indicatorsList;
        const spacer = 20;

        let tinySlider = tns({
            container: this.slider,
            items: 2,
            gutter: spacer,
            edgePadding: spacer,
            //lazyload: true,
            //lazyloadSelector: '.testimonials-slide-header img',
            controlsContainer: this.sliderControls,
            prevButton: this.prevBtn,
            nextButton: this.nextBtn,
            autoplay: true,
            mouseDrag: true,
            center: true,
            nav: false,
            autoHeight: true,
            // onInit: function(info) {
            //     info.controlsContainer.setAttribute('tabindex', -1);
            //     info.nextButton.setAttribute('tabindex', 0);
            //     info.prevButton.setAttribute('tabindex', 0);
            // },

            //navContainer: this.indicatorsList,
            //fixedWidth: 390,
            responsive: {
                480: {
                    items: 2,
                },
                768: {
                    items: 3,
                },
                1260: {
                    items: 4,
                    gutter: spacer,
                    edgePadding: spacer
                }
            }
        });

    }

} export default ComponentPartnersSlider;
