import Constants from "./Constants";
class ComponentCustomSelect {

    constructor() {
        this.selectElements = document.querySelectorAll("[data-mtd-select]");

        if (this.selectElements) {
            this.initCustomSelect();
        }
    }

    initCustomSelect() {
        const selectElements = this.selectElements;

        selectElements.forEach(select => {
            const selectSelected = select.querySelector(".select-selected");
            const selectItems = select.querySelector(".select-items");
            const options = selectItems.querySelectorAll(".select-option");
            const hiddenInput = select.querySelector("[data-mtd-input]");

            select.addEventListener("click", function () {
                selectItems.classList.toggle(Constants.classIsOpened);
              /*  if(selectItems.classList.contains(Constants.classIsOpened))
                    selectItems.classList.remove(Constants.classIsOpened);
                else {
                    selectItems.classList.add(Constants.classIsOpened);
                }*/
            });

            for (const option of options) {
                let optionValue = Number(option.dataset.value);

                option.addEventListener("click", function () {
                    selectSelected.textContent = this.textContent;
                    selectSelected.classList.add('is--selected');

                    hiddenInput.value = optionValue;

                    setTimeout(hideIt, 100);
                    function hideIt(){
                        if(selectItems.classList.contains(Constants.classIsOpened))
                            selectItems.classList.remove(Constants.classIsOpened);
                    }
                });
            }

            document.addEventListener("click", function (e) {
                if (!select.contains(e.target)) {
                    if(selectItems.classList.contains(Constants.classIsOpened))
                        selectItems.classList.remove(Constants.classIsOpened);
                }
            });
        });
    }

} export default ComponentCustomSelect;
