import Constants from "./Constants";

class ComponentAccordions {

    constructor() {
        this.accordions = document.querySelectorAll("[data-accordion]");

        if (this.accordions) {
            this.initAccordions();
        }
    }

    initAccordions() {
        const triggers = this.accordions;

        triggers.forEach(accordion => {
            accordion.addEventListener('click', evt => {
                if(accordion.classList.contains(Constants.classIsOpened)){
                    accordion.classList.remove(Constants.classIsOpened);
                }
                else {
                    this.closeAccordions();
                    accordion.classList.add(Constants.classIsOpened);
                }
            });
        });
    }

    closeAccordions() {
        const triggers = this.accordions;
        triggers.forEach(accordion => {
            accordion.classList.remove(Constants.classIsOpened);
        });
    }

} export default ComponentAccordions;
