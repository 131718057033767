import './libs/offset';
import './libs/smoothScroll';

import ComponentNavigation from "./ComponentNavigation";
import ComponentOpenerVideo from "./ComponentOpenerVideo";
import ComponentPartnersSlider from "./ComponentPartnersSlider";
import ComponentTestimonialsSlider from "./ComponentTestimonialsSlider";
import ComponentHotOffersSlider from "./ComponentHotOffersSlider";
import ComponentCarsFilter from "./ComponentCarsFilter";
import ComponentCustomSelect from "./ComponentCustomSelect";
import ComponentFormOrder from "./ComponentFormOrder";
import ComponentAccordions from "./ComponentAccordions";
import ComponentStyleBg from "./ComponentStyleBg";

document.addEventListener("DOMContentLoaded", () => {
    new ComponentNavigation();
    new ComponentOpenerVideo();
    new ComponentPartnersSlider();
    new ComponentHotOffersSlider();
    new ComponentTestimonialsSlider();
    new ComponentCarsFilter();
    new ComponentCustomSelect();
    new ComponentFormOrder();
    new ComponentAccordions();
    new ComponentStyleBg();
});

document.addEventListener('DOMContentLoaded', function () {
    /*cookieconsent.run({
        "notice_banner_type":"simple",
        "consent_type":"express",
        "palette":"light",
        "language":"cs",
        "page_load_consent_levels":
            [
                "strictly-necessary"
            ],
        "notice_banner_reject_button_hide":false,
        "preferences_center_close_button_hide":false,
        "page_refresh_confirmation_buttons":false,
        "website_name":"horimexcars.cz",
        //"website_privacy_policy_url":"https://www.horimexcars.cz/pouzivani-souboru-cookies",
        "callbacks": {
            "scripts_specific_loaded": (level) => {
                switch(level) {
                    case 'targeting':
                        gtag('consent', 'update', {
                            'ad_storage': 'granted',
                            'ad_user_data': 'granted',
                            'ad_personalization': 'granted',
                            'analytics_storage': 'granted'
                        });
                        break;
                }
            }
        },
        "callbacks_force": true
    });*/
});