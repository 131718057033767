class ComponentStyleBg {

    constructor() {
        this.styleBgs = document.querySelectorAll("[data-style-bg]");

        if (this.styleBgs){
            this.initStyleBg();
        }
    }

    initStyleBg() {
        //set opener bg image by data attribute "data-style-bg"

        let styleBg = this.styleBgs;

        for (let i = 0; i < styleBg.length; i++) {
            let el = styleBg[i];
            let bgUrl = el.dataset.styleBg;
            let webpUrl = bgUrl.replace(/\.[^/.]+$/, ".webp"); //odstrani koncovku a prida .webp

            el.style.cssText += "background: url("+bgUrl+") no-repeat cover; background-image: -webkit-image-set(url("+webpUrl+") 1x);";

            //el.style.backgroundImage = "url("+bgUrl+")";
            //el.style.backgroundImage += "-webkit-image-set(url("+webpUrl+") 1x)";
        }
    }

} export default ComponentStyleBg;
