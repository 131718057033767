import Constants from "./Constants";

class ComponentNavigation {

    constructor() {
        this.header = document.querySelector(".header");

        this.menu = document.querySelector(".navigation");
        this.menuMain = document.querySelector(".navigation__items");
        this.menuSub = document.querySelector(".navigation__right");
        this.scrollElements = document.querySelectorAll("[data-scroll-target]");
        this.hamburger = this.menu.querySelector(".hamburger");
        this.smoothScroll = window.smoothScrollTo;
        this.menuHeight = 0;
        this.dropdownElements = document.querySelectorAll("[data-dropdown-target]");
        this.dropdownTopElements = document.querySelectorAll("[data-dropdown-top-target]");
        this.topbar = document.querySelector('.topbar');

        this.initMenuHeight();
        this.handleScrollTargets();
        if(this.hamburger){
            this.initHamburger();
        }
        if(this.dropdownElements){
            this.handleDropdowns();
        }
        if(this.dropdownTopElements){
            this.handleDropdownsTop();
        }
        if(this.topbar){
            this.hideTopBarOnScroll();
        }

        this.initOffCanvas();

        /*if(this.header){
            this.handleStickyHeader();
        }*/
    }

    handleScrollTargets() {
        //scroll to element if cookies 'scrollTo' is defined
        //this is in case if the element, we want to scroll to, is on different page
        let scrollC =  getCookie('scrollTo');
        //console.log(scrollC);
        if(scrollC){
            scrollToID(scrollC);
            delCookie('scrollTo');
        }

        for (let i = 0; i < this.scrollElements.length; i++) {
            let el = this.scrollElements[i];
            el.addEventListener('click', (e) => {
                e.preventDefault();
                let targetHref = el.href;
                let target = el.dataset.scrollTarget;
                let targetEl = document.querySelector(`${target}`);

                if(targetHref.toString().includes("#") === true){
                    scrollToID(target, el);
                }
                else{
                    delCookie('scrollTo');
                    setCookie('scrollTo', target, 1);
                    window.location.href = targetHref;
                }
            });
        }

        /**
         * Scroll to element, redirect if href is defined
         * @param {object} target
         * @param {object} el
         */
        function scrollToID(target, el = null) {
            let targetC = target;//.split('#')[1];
            let targetEl = document.querySelector(`${targetC}`);
            //console.log(targetEl);
            if(targetEl) {
                window.smoothScrollTo(0, window.offset(targetEl).top - 130, 1500);
            }
        }
        /**
         * Set a cookie
         * @param {string} cName
         * @param {string} cValue
         * @param {number} exp - expiration in days
         */
        function setCookie(cName, cValue, exp) {
            const d = new Date();
            d.setTime(d.getTime() + (exp*24*60*60*1000));
            let expires = "expires="+ d.toUTCString();
            document.cookie = cName + "=" + cValue + ";" + expires + ";path=/";
        }

        /**
         * Get a cookie
         * @param {string} cName
         */
        function getCookie(cName) {
            let name = cName + "=";
            let decodedCookie = decodeURIComponent(document.cookie);
            let ca = decodedCookie.split(';');
            for(let i = 0; i <ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }

        /**
         * Delete a cookie
         * @param {string} cName
         */
        function delCookie(cName) {
            //document.cookie = cName+'=; Max-Age=-99999999;';
            //document.cookie = cName+'=; Max-Age=0; path=/; domain=' + location.host;
            //document.cookie = cName+'= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
            //document.cookie = cName + '=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; domain=' + location.host;
            let expires = new Date();
            expires.setTime(expires.getTime() - 1000);
            document.cookie = cName + "=; expires=" + expires.toUTCString() + "; path=/";
        }
    }

    /*handleScrollTargets() {
        for (let i = 0; i < this.scrollElements.length; i++) {
            let el = this.scrollElements[i];
            el.addEventListener('click', (e) => {
                e.preventDefault();
                let target = el.dataset.scrollTarget;
                let targetEl = document.querySelector(`${target}`);
                this.smoothScroll(0, window.offset(targetEl).top - this.menuHeight, 1500);
            });
        }
    }*/

    initOffCanvas(){
        const mainNav = this.menu;

        const logoEl = document.querySelector('[data-nav-logo]');
        const logoDiv = document.createElement('div');
        logoDiv.classList.add('off-canvas__logo');
        logoDiv.appendChild(logoEl.cloneNode(true));
        // Kopírování obsahu divu s atributem [data-nav-content] do off-canvas menu
        const navContent = document.querySelector('[data-nav-content]');
        const offCanvas = document.createElement('div');
        const offCanvasNav = document.createElement('nav');
        const offCanvasNavList = document.createElement('div');

        offCanvas.classList.add('off-canvas');
        offCanvasNav.classList.add('nav-off-canvas');
        offCanvasNavList.classList.add('nav-items');
        offCanvasNavList.innerHTML = navContent.innerHTML;

        offCanvasNav.appendChild(offCanvasNavList);
        offCanvas.appendChild(logoDiv);
        offCanvas.appendChild(offCanvasNav);
        document.body.appendChild(offCanvas);

        // Aktualizace dropdownů po kopírování obsahu
        const canvasDropdowns = offCanvas.querySelectorAll('[data-dropdown-target]');
        function handleDropdownsOffCanvas() {
            for (let i = 0; i < canvasDropdowns.length; i++) {
                let el = canvasDropdowns[i];
                let target = el.dataset.dropdownTarget;
                let targetEl = offCanvas.querySelector("[data-dropdown="+target+"]");
                let winWidth = window.screen.availWidth;

                el.addEventListener('click', (e) => {
                   // console.log(canvasDropdowns);
                    targetEl.classList.toggle(Constants.classDropdownOpened);
                });

                if(targetEl != null && winWidth > 991){
                    el.addEventListener("mouseover", (e) => {
                        targetEl.classList.add(Constants.classDropdownOpened);
                    });
                    targetEl.addEventListener("mouseleave", (e) => {
                        targetEl.classList.remove(Constants.classDropdownOpened);
                    });
                }
            }
        }

        // Přidání tlačítka pro otevření/zavření off-canvas menu
        const toggleNavBtn = this.hamburger;
        toggleNavBtn.addEventListener('click', () => {
            offCanvas.classList.toggle(Constants.classOffCanvasOpened);
            handleDropdownsOffCanvas();
        });

        const wrapper = document.querySelector('.main-content');

        wrapper.addEventListener('click', function (event) {
            if (offCanvas.classList.contains(Constants.classOffCanvasOpened) && !event.target.closest('.off-canvas')) {
                offCanvas.classList.remove(Constants.classOffCanvasOpened);
                mainNav.classList.remove(Constants.classNavigationOpened);
            }
        });
    }

    hideTopBarOnScroll(){
        let scrollPosition = window.scrollY;
        let topBar = this.topbar;
        let header = this.header;

        window.addEventListener('scroll', function() {
            scrollPosition = window.scrollY;

            if (scrollPosition >= 200) {
                topBar.classList.add(Constants.classIsHidden);
                header.classList.add(Constants.classIsSticky);

            } else {
                topBar.classList.remove(Constants.classIsHidden);
                header.classList.remove(Constants.classIsSticky);
            }
        });
    }

    initMenuHeight() {
        //this.menuHeight = this.menu.offsetHeight;
        this.menuHeight = this.header.offsetHeight + 30;
        window.addEventListener("resize", e => {
           this.menuHeight = this.header.offsetHeight + 30;
        });
    }

    initHamburger() {
        this.hamburger.addEventListener("click", e => {
           this.menu.classList.toggle(Constants.classNavigationOpened);

            //let mainMenu = this.menuMain;
            //let subMenu = this.menuSub;
            //node.appendChild(textnode);

            //subMenu.style.display = "block";
            //mainMenu.appendChild(subMenu);
        });
    }

    handleStickyHeader() {
        let scrollPosition = window.scrollY;
        const pageHeader = this.header;
        const headerWrap = pageHeader.querySelector('.header-wrapper');
        const headerLogo = headerWrap.querySelector('[data-nav-logo]');
        const stickyLogoPath = headerWrap.querySelector('[data-logo-sticky]').getAttribute('data-logo-sticky');
        const headerInfo = headerWrap.querySelector('[data-nav-info]');
        const headerNavbar = headerWrap.querySelector('[data-nav-navbar]');

        const headerPhone = headerWrap.querySelector('[data-nav-phone]');
        const headerEmail = headerWrap.querySelector('[data-nav-email]');
        const priceListLink = headerInfo.getAttribute('data-pricelist');

        //const stickyHeader = document.createElement('div');
        //stickyHeader.classList.add('sticky-header');
        //stickyHeader.innerHTML = navContent.innerHTML;

        const stickyHeader = document.createElement('div');
        stickyHeader.classList.add('sticky-header-wrapper');
        stickyHeader.innerHTML = `                  
                <div class="sticky-header__logo">
                    <a href="${headerLogo.href}" title="Účetní a daňová kancelář T&K s.r.o." data-nav-logo>
                        <picture>
                            <source srcset="${stickyLogoPath}.webp" type="image/webp">
                            <source srcset="${stickyLogoPath}.png" type="image/png">
                            <img src="${stickyLogoPath}.png" alt="T&K">
                        </picture>
                    </a>
                </div>
                <div class="sticky-header__nav">
                    <nav class="navigation navigation--sticky" role="navigation" aria-label="Main navigation">
                        ${headerNavbar.innerHTML}
                    </nav>                            
                </div>
                <div class="sticky-header__info">
                    <div class="sticky-header__info-lg">
                        ${headerInfo.innerHTML}
                    </div>
                    <div class="sticky-header__info-md">
                        <ul>
                            <li>
                                <Link to="${priceListLink}" title="Cenová nabídka zdarma">Cenová nabídka zdarma</Link>
                            </li>
                            <li>
                                <Link to="${headerPhone.href}" title="Volat ${headerPhone.innerHTML}">${headerPhone.innerHTML}</Link>                                   
                            </li>
                            <li>
                                <Link to="${headerEmail.href}" title="Email: ${headerEmail.innerHTML}">${headerEmail.innerHTML}</Link>
                            </li>
                        </ul>
                    </div>                
                </div>                                              
            `;


        window.addEventListener('scroll', function() {
            scrollPosition = window.scrollY;

            if (scrollPosition >= 40) {
                pageHeader.classList.add('header--sticky');
                pageHeader.appendChild(stickyHeader);
                headerWrap.remove();
            } else {
                stickyHeader.remove();
                pageHeader.classList.remove('header--sticky');
                pageHeader.appendChild(headerWrap);
            }

        });

        function insertAfter(referenceNode, newNode) {
            referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
        }
    }

    handleDropdowns() {
        for (let i = 0; i < this.dropdownElements.length; i++) {
            let el = this.dropdownElements[i];
            let target = el.dataset.dropdownTarget;
            let targetEl = document.querySelector("[data-dropdown="+target+"]");
            let winWidth = window.screen.availWidth;

            el.addEventListener('click', (e) => {
                e.preventDefault();
                targetEl.classList.toggle(Constants.classDropdownOpened);
            });

            if(targetEl != null && winWidth > 991){
                el.addEventListener("mouseover", (e) => {
                    targetEl.classList.add(Constants.classDropdownOpened);
                });
                targetEl.addEventListener("mouseleave", (e) => {
                    targetEl.classList.remove(Constants.classDropdownOpened);
                });
            }
        }
    }


    handleDropdownsTop() {
        for (let i = 0; i < this.dropdownTopElements.length; i++) {
            let el = this.dropdownTopElements[i];
            let target = el.dataset.dropdownTopTarget;
            let targetEl = document.querySelector("[data-dropdown-top="+target+"]");

            el.addEventListener('click', (e) => {
                e.preventDefault();
                closeAllDropdowns();
                targetEl.classList.add(Constants.classDropdownOpened);
            });
            //if clicked outside close dropdown
            //const wrapper = document.querySelector('.main-content');
            document.addEventListener('click', function (event) {
                if (!event.target.closest('.topbar') && !event.target.closest('.list--topbar')) {
                    targetEl.classList.remove(Constants.classDropdownOpened);
                }
            });

        }

        function closeAllDropdowns() {
            let getDropdowns = document.querySelectorAll('[data-dropdown-top]');
            getDropdowns.forEach((el) => {
                el.classList.remove(Constants.classDropdownOpened);
            });
        }
    }

} export default ComponentNavigation;
