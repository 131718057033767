import Constants from "./Constants";

class ComponentCarsFilter {

    constructor() {
        this.carsFilter = document.querySelector("[data-cars-filter]");

        if (this.carsFilter) {
            this.carsFilterTrigger = document.querySelector("[data-cars-filter-trigger]");
            this.carsFilterBody = document.querySelector("[data-cars-filter-body]");
            this.filterDropdown();
        }
    }

    filterDropdown() {
        const trigger = this.carsFilterTrigger;
        const filterBody = this.carsFilterBody;

        trigger.addEventListener('click', evt => {
           filterBody.classList.toggle(Constants.classDropdownOpened);
        });
    }

} export default ComponentCarsFilter;
