const CLASS_TEMPLATE = "class-template";
const CLASS_NAVIGATION_OPENED = "navigation--opened";
const CLASS_DROPDOWN_OPENED = "dropdown--opened";
const CLASS_OFF_CANVAS_OPENED = "off-canvas--opened";
const CLASS_IS_HIDDEN = "is--hidden";
const CLASS_IS_STICKY = "is--sticky";
const CLASS_IS_OPENED = "is--opened";

class Constants {
    static get classTemplate(){ return CLASS_TEMPLATE; }
    static get classNavigationOpened() { return CLASS_NAVIGATION_OPENED; }
    static get classDropdownOpened() { return CLASS_DROPDOWN_OPENED; }
    static get classOffCanvasOpened() { return CLASS_OFF_CANVAS_OPENED; }
    static get classIsHidden() { return CLASS_IS_HIDDEN; }
    static get classIsSticky() { return CLASS_IS_STICKY; }
    static get classIsOpened() { return CLASS_IS_OPENED; }

} export default Constants;
